var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{attrs:{"columns":_vm.columns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/serve/serve-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"位置"}},[_c('Select',{attrs:{"options":_vm.commonAreaList,"replace-fields":{
            lable: 'text',
            value: 'value',
          },"placeholder":"请选择位置"},model:{value:(queryParam.areaUUID),callback:function ($$v) {_vm.$set(queryParam, "areaUUID", $$v)},expression:"queryParam.areaUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"巡检结果"}},[_c('Select',{attrs:{"options":_vm.violationFlag,"replace-fields":{
            lable: 'msg',
            value: 'code',
          },"placeholder":"请选择巡检结果"},model:{value:(queryParam.violationFlag),callback:function ($$v) {_vm.$set(queryParam, "violationFlag", $$v)},expression:"queryParam.violationFlag"}})],1),_c('QueryLayout',{attrs:{"lable":"时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]}},{key:"table-item-imageList",fn:function(ref){
          var row = ref.row;
return (row.violationFlag == '02' && row.imageList && row.imageList[0])?_c('img',{style:({
        cursor: _vm.isImage(row.imageList[0]) ? 'pointer' : 'auto',
      }),attrs:{"src":("" + _vm.$imgUrl + (row.imageList[0])),"width":"100","height":"48","alt":"违规1"},on:{"click":function($event){_vm.isImage(row.imageList[0])
          ? _vm.$bus.$emit('ImgDialog', {
              imgUrl: ("" + _vm.$imgUrl + (row.imageList[0])),
            })
          : null}}}):_vm._e()}},{key:"table-item-action",fn:function(ref){
          var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"color":"#25c4ff"},on:{"click":function($event){return _vm.$refs.inspectionInfoDialog.openDialog({ row: row })}}},[_vm._v(" 查看 ")])])}}],null,true)}),_c('InspectionInfoDialog',{ref:"inspectionInfoDialog"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }