<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <div style="display: flex; align-items: center">
      <v-row style="flex: 1; margin: 0">
        <template v-for="e of panel">
          <v-col
            v-if="e.visible ? e.visible(info) : true"
            :key="e.field"
            cols="12"
          >
            <span>{{ e.text }}：</span>
            <span class="breakAll" :style="e.style ? e.style(info) : {}">{{
              e.format
                ? e.format(info[e.field])
                : info[e.field]
                ? info[e.field]
                : '-'
            }}</span>
          </v-col>
        </template>
      </v-row>
      <div
        style="
          width: 960px;
          height: 540px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;
        "
      >
        <div
          v-if="info.attachList && info.attachList.length"
          class="attach-filter"
        >
          <span
            v-for="index in info.attachList.length"
            :key="index"
            :class="{ active: index === attachActive }"
            @click="attachActive = index"
            >{{ index }}</span
          >
        </div>
        <img
          v-if="
            !info.attachList ||
            !info.attachList[0] ||
            isImage(info.attachList[attachActive - 1])
          "
          v-lazy="
            `${$imgUrl}${info.attachList && info.attachList[attachActive - 1]}`
          "
          :style="`height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:${
            isImage(info.attachList && info.attachList[attachActive - 1])
              ? 'pointer'
              : 'auto'
          }`"
          alt="违规"
          @click="
            info.attachList && info.attachList[attachActive - 1]
              ? $bus.$emit('ImgDialog', {
                  imgUrl: `${$imgUrl}${info.attachList[attachActive - 1]}`,
                })
              : null
          "
        />
        <template v-else>
          <video
            :src="`${$imgUrl}${info.attachList[attachActive - 1]}`"
            controls
            style="flex: 1; object-fit: contain; overflow: hidden; height: 100%"
          ></video>
        </template>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      attachActive: 1,
      panel: [
        {
          text: '机构',
          field: 'organizationText',
        },
        {
          text: '位置',
          field: 'areaText',
        },
        {
          text: '操作位置',
          field: 'cameraName',
        },
        {
          text: '巡查人',
          field: 'operatorName',
        },
        {
          text: '开始时间',
          field: 'startTime',
        },
        {
          text: '结束时间',
          field: 'endTime',
        },
        {
          text: '巡检结果',
          field: 'violationFlagText',
        },
        {
          text: '违规描述',
          field: 'description',
        },
      ],
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1320,
          title: '远程巡检详情',
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    isImage,
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      if (this.dialogOptions.row && this.dialogOptions.row.uuid)
        this.getInfo(
          this.dialogOptions.row[
            this.dialogOptions.row.isTask == 1 ? 'callId' : 'uuid'
          ],
          options.row
        );
      this.visible = true;
    },
    async getInfo(uuid, row) {
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/serve/info/${uuid}`
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.info = data.data[0];
        this.info.violationFlagText = row.violationFlagText;
      } catch (error) {
        this.kalert({
          type: 'error',
          content: error + '',
        });
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}

.attach-filter {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;

  span {
    padding: 0 10px;
    background: #eeeeee;
    color: #000;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    font-size: 16px;

    &.active {
      color: #fff;
      background: #909399;
    }
  }
}
</style>
