<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/serve/serve-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="位置">
          <Select
            v-model="queryParam.areaUUID"
            :options="commonAreaList"
            :replace-fields="{
              lable: 'text',
              value: 'value',
            }"
            placeholder="请选择位置"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="巡检结果">
          <Select
            v-model="queryParam.violationFlag"
            :options="violationFlag"
            :replace-fields="{
              lable: 'msg',
              value: 'code',
            }"
            placeholder="请选择巡检结果"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="时间">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>
      <img
        v-if="row.violationFlag == '02' && row.imageList && row.imageList[0]"
        slot="table-item-imageList"
        slot-scope="{ row }"
        :src="`${$imgUrl}${row.imageList[0]}`"
        width="100"
        height="48"
        alt="违规1"
        :style="{
          cursor: isImage(row.imageList[0]) ? 'pointer' : 'auto',
        }"
        @click="
          isImage(row.imageList[0])
            ? $bus.$emit('ImgDialog', {
                imgUrl: `${$imgUrl}${row.imageList[0]}`,
              })
            : null
        "
      />
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
      >
        <div
          style="color: #25c4ff"
          @click="$refs.inspectionInfoDialog.openDialog({ row })"
        >
          查看
        </div>
      </div>
    </TableLayout>
    <InspectionInfoDialog ref="inspectionInfoDialog"></InspectionInfoDialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import InspectionInfoDialog from './InspectionInfoDialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    InspectionInfoDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '远程巡检记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '位置',
          value: 'areaText',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作位置',
          value: 'cameraName',
          sortable: false,
          align: 'center',
        },
        {
          text: '巡查人',
          value: 'operatorName',
          sortable: false,
          align: 'center',
        },
        {
          text: '开始时间',
          value: 'startTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '结束时间',
          value: 'endTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '巡检结果',
          value: 'violationFlagText',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规描述',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规截图',
          value: 'imageList',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      commonAreaList: (state) =>
        state.screenStore.analysisitem['commonAreaList']
          ? state.screenStore.analysisitem['commonAreaList']
          : [],
      violationFlag: (state) =>
        state.screenStore.commondict['MonitorDataViolationFlagEnum']
          ? state.screenStore.commondict['MonitorDataViolationFlagEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogOptions = {
          dialog: {
            width: 1500,
            title: '远程巡检记录',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    isImage,
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
