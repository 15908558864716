var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-row',{staticStyle:{"flex":"1","margin":"0"}},[_vm._l((_vm.panel),function(e){return [(e.visible ? e.visible(_vm.info) : true)?_c('v-col',{key:e.field,attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(e.text)+"：")]),_c('span',{staticClass:"breakAll",style:(e.style ? e.style(_vm.info) : {})},[_vm._v(_vm._s(e.format ? e.format(_vm.info[e.field]) : _vm.info[e.field] ? _vm.info[e.field] : '-'))])]):_vm._e()]})],2),_c('div',{staticStyle:{"width":"960px","height":"540px","display":"flex","align-items":"center","justify-content":"center","overflow":"hidden","position":"relative"}},[(_vm.info.attachList && _vm.info.attachList.length)?_c('div',{staticClass:"attach-filter"},_vm._l((_vm.info.attachList.length),function(index){return _c('span',{key:index,class:{ active: index === _vm.attachActive },on:{"click":function($event){_vm.attachActive = index}}},[_vm._v(_vm._s(index))])}),0):_vm._e(),(
          !_vm.info.attachList ||
          !_vm.info.attachList[0] ||
          _vm.isImage(_vm.info.attachList[_vm.attachActive - 1])
        )?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
          ("" + _vm.$imgUrl + (_vm.info.attachList && _vm.info.attachList[_vm.attachActive - 1]))
        ),expression:"\n          `${$imgUrl}${info.attachList && info.attachList[attachActive - 1]}`\n        "}],style:(("height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:" + (_vm.isImage(_vm.info.attachList && _vm.info.attachList[_vm.attachActive - 1])
            ? 'pointer'
            : 'auto'))),attrs:{"alt":"违规"},on:{"click":function($event){_vm.info.attachList && _vm.info.attachList[_vm.attachActive - 1]
            ? _vm.$bus.$emit('ImgDialog', {
                imgUrl: ("" + _vm.$imgUrl + (_vm.info.attachList[_vm.attachActive - 1])),
              })
            : null}}}):[_c('video',{staticStyle:{"flex":"1","object-fit":"contain","overflow":"hidden","height":"100%"},attrs:{"src":("" + _vm.$imgUrl + (_vm.info.attachList[_vm.attachActive - 1])),"controls":""}})]],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }